import auWarrantyPricing from './pricing/AU.json';
import usWarrantyPricing from './pricing/US.json';
import nzWarrantyPricing from './pricing/NZ.json';
import sgWarrantyPricing from './pricing/SG.json';
import devWarrantyPricing from './pricing/DEV.json';

export type WarrantyItemPrice = {
  from: number;
  to: number;
  price: number;
  variantId: number;
};

let warrantyPricing = {};

if (process.env.NEXT_PUBLIC_STORE === 'reebelo-au')
  warrantyPricing = auWarrantyPricing;

if (process.env.NEXT_PUBLIC_STORE === 'reebelo-us')
  warrantyPricing = usWarrantyPricing;

if (process.env.NEXT_PUBLIC_STORE === 'reebelo-nz')
  warrantyPricing = nzWarrantyPricing;

if (process.env.NEXT_PUBLIC_STORE === 'quista')
  warrantyPricing = sgWarrantyPricing;

if (process.env.NEXT_PUBLIC_STORE === 'reebelo-dev')
  warrantyPricing = devWarrantyPricing;

export default warrantyPricing as Record<string, Array<WarrantyItemPrice>>;
